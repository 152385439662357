import React, {useState} from 'react'
import styled, {keyframes} from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import {Heart} from '@styled-icons/entypo/Heart';
import {PlayVideo as Video} from "@styled-icons/foundation/PlayVideo";
import Container from '../layout/Container';
import {H1, Button, P} from '../elements';
import VideoModal from '../general/VideoModal';
import SVGVideoIcon from '../svg/VideoIcon';
import {
  colors,
  breakpoints,
} from '../../styles/_variables';


const Section = styled.section.attrs({
  className: `w-full h-full min-h-screen relative z-0`
})`
  background-image: url(${p => p.image.childImageSharp.fluid.src});
  background-repeat: no-repeat;
  background-position: 75% 50%;
  background-size: cover;
`;
const ContainerC = styled(Container).attrs({
  className: `w-full h-full pt-20`
})`
`;
const Content = styled.div.attrs({
  className: `flex flex-col justify-end pt-4 pb-10 items-start
  w-full md:w-2/3 text-white relative`
})`
  z-index: 1;
  min-height: 85vh;
  color: white;
  @media screen and (min-width: ${breakpoints.md}) {
    min-height: 80vh;
  }
`;
const Description = styled.div.attrs({
  className: `mt-1 mb-2 xs:mt-4 xs:mb-4`,
})`
`;
const videoIconAnim = keyframes`
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
`;
const borderAnim = keyframes`
  from {
    stroke-dashoffset: 300;
  }
  to {
    stroke-dashoffset: 0;
  }
`;
const VideoIcon = styled(SVGVideoIcon).attrs({
  className: `mr-2`,
  width: '1.6em',
})`
  .border {
    stroke-dasharray: 150 150;
    stroke-dashoffset: 20;
    animation: ${borderAnim} ease 5s infinite;
  }
`;
const VideoButton = styled.button.attrs({
  className: `outline-none flex items-center border-none 
  ml-3 md:ml-5 lg:ml-0 mr-4 md:mr-2
  text-xl px:4 md:px-5 lg:px-6 py-2 
  tracking-wide`
})`
`;
const VideoC = styled.video`
  width: 100%;
  height: 100%;
`;

export default function hero({data}) {
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const handleConnectClick = () => {
    setModal1Open(true);
  }
  const handleCommitClick = () => {
    setModal2Open(true);
  }
  return (
    <Section image={data.image}>
      <ContainerC>
        <Content>
          <H1 white dangerouslySetInnerHTML={{__html: data.heading}} />
          <Description>
            <P>{data.description}</P>
            <P fw={'bold'}>{data.tagline}</P>
          </Description>
          <div className="flex mb-5">
            <VideoButton onClick={handleConnectClick}>
              <VideoIcon /> Connect
            </VideoButton>
            <VideoButton onClick={handleCommitClick}>
              <VideoIcon /> Commit
            </VideoButton>
          </div>
          <Button long to="/donate">
            {data.cta}&nbsp;&nbsp;<Heart size={'1.2em'} />
          </Button>
        </Content>
      </ContainerC>
      <VideoModal isVideoModalOpen={modal1Open} setIfVideoModalOpen={setModal1Open}>
        <VideoC controls controlsList="nodownload">
          <source src="/videos/EkAurEk-Connect.mp4" type="video/mp4" />
        </VideoC>
      </VideoModal>
      <VideoModal isVideoModalOpen={modal2Open} setIfVideoModalOpen={setModal2Open}>
        <VideoC controls controlsList="nodownload">
          <source src="/videos/EkAurEk-Commit.mp4" type="video/mp4" />
        </VideoC>
      </VideoModal>
    </Section>
  )
}
