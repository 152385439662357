import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      height="100%"
      width="100%"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="border"
        d="M5.5 7h20c.831 0 1.5.669 1.5 1.5v14c0 .831-.669 1.5-1.5 1.5h-20c-.831 0-1.5-.669-1.5-1.5v-14C4 7.669 4.669 7 5.5 7z"
        stroke="#fff" strokeWidth="2" fill="transparent"
      />
      <path className="inner-icon" d="M12.497 11l7 4.5-7 4.5z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
