import React from 'react'
import styled from 'styled-components';
import Section from '../layout/Section';
import Container from '../layout/Container';
import Img from 'gatsby-image';
import {P, P3, Heading} from '../elements';
import { useStaticQuery, graphql } from 'gatsby';
import tw from 'twin.macro';
import {
  responsive_font,
} from '../../styles/_mixins';
import { colors } from '../../styles/_variables';

const Grid = styled.div.attrs({
  className: `flex flex-col md:flex-row h-full`
})`
`;
const Description = styled(Heading).attrs({
  className: `hidden mt-8 md:block w-4/5 font-semibold
  leading-8`
})`
  span {
    display: block;
  }
`;
const DescriptionMobile = styled(P).attrs({
  className: `md:hidden mb-8 leading-relaxed order-first`
})`
`;
const Content = styled.div.attrs({
  className: `md:w-1/2 flex flex-col justify-between
    lg:py-4 md:mb-10 lg:mb-20`
})`
  color: ${colors.text_black};

`;
const MetricsDiv = styled.div.attrs({
  className: `w-full mt-8 md:mt-0`
})`
`;
const IllustrationDiv = styled.div.attrs({
  className: `md:w-1/2 p-4 lg:pl-10 order-first md:order-none`
})`
`;
const ImgC = styled(Img).attrs({
  className: `h-full w-full`,
  imgStyle: { objectFit: 'contain', objectPosition: "50% 50%", },
})`
`;
const Amount = styled.span.attrs({
  className: `font-bold`
})`
  ${responsive_font('huge')};
`;
const Progress = styled.div.attrs({
  className: `h-4 w-full rounded-lg my-8
    relative`
})`
  background-color: ${colors.white};
  overflow: hidden;
  &::before {
    height: 100%;
    border-radius: 2px;
    width: ${p => `${p.width}%`};
    content: "";
    background-color: ${colors.primary};
    display: block;

  }
`;
const MerticsItems = styled.div.attrs({
  className: `flex`
})`
`;
const MerticsItem = styled.div.attrs({
  className: `flex flex-col mr-12`
})`
  & span:nth-child(1) {
    ${responsive_font('h1')};
    ${tw`pb-4 font-bold`};
  }
  & span:nth-child(2) {
    text-transform: uppercase;
  }
`;


export default function Metrics(props) {
  const {data} = props;
  const image = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "images/home/metrics-illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  );
  return (
    <Section bg={colors.bg_secondary} screen>
      <Container>
        <Grid>
          <DescriptionMobile dangerouslySetInnerHTML={{__html: data.heading}} />
          <Content>
              <Description
                fw="300"
                dangerouslySetInnerHTML={{__html: data.heading}}
              />
            <MetricsDiv>
              <Amount>₹{data.amount}</Amount>
              <P3 className="mt-8 font-normal uppercase">{data.amountFrom}</P3>
              <Progress width={data.progress} />
              <MerticsItems>
                <MerticsItem>
                  <span>{data.metrics.metric1.number}</span>
                  <span>{data.metrics.metric1.name}</span>
                </MerticsItem>
                <MerticsItem>
                  <span>{data.metrics.metric2.number}</span>
                  <span>{data.metrics.metric2.name}</span>
                </MerticsItem>
                <MerticsItem>
                  <span>{data.metrics.metric3.number}</span>
                  <span>{data.metrics.metric3.name}</span>
                </MerticsItem>
              </MerticsItems>
            </MetricsDiv>
          </Content>
          <IllustrationDiv>
            <ImgC 
              fluid={image.illustration.childImageSharp.fluid}
            />
          </IllustrationDiv>
        </Grid>
      </Container>
    </Section>
  )
}
