import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql, StaticQuery, Link } from 'gatsby';
import Section from './layout/Section';
import Container from './layout/Container';
import {SectionTitle, Img, Button} from './elements';
import getFormattedDate from '../utils/getFormattedDate';
import {
  colors,
  breakpoints,
} from '../styles/_variables';
import {
  hide_scrollbar,
  custom_scrollbar,
} from '../styles/_mixins';

const A = styled.a.attrs({
  className: `inline`
})`
  border: 1px solid red;
`;
const ButtonC = styled(Button).attrs({
  className: `m-auto`,
})`
  display: block;
  margin: auto;
`;
const Partners = styled.div.attrs(p => ({
  className: `mt-16 mb-6 ${p.noButton && 'mb-2'}
    flex items-center justify-evenly flex-row`
}))`
  flex-wrap: wrap;
`;
const Partner = styled.div.attrs({
  className: `h-20 px-5 mb-10`
})`
  width: 50%;
  @media screen and (min-width: ${breakpoints.md}) {
    width: 25%;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 15.15%;
  }
`;

export default function HowItWorks({data, noButton}) {
  return (
    <StaticQuery
    query={graphql`
      query partnersQuery {
        markdownRemark(frontmatter: {content: {eq: "partners"}}) {
          frontmatter {
            title
            partners {
              name
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  `}
      render={(data, count) => {
        const content = data.markdownRemark.frontmatter;
        return (
          <Section bg={colors.bg_white}>
            <Container>
              <SectionTitle>{content.title}</SectionTitle>
              <Partners noButton={noButton}>
                {content.partners.map(it => {
                  return (
                    <Partner>
                      <Img 
                        fluid={it.image.childImageSharp.fluid} 
                        alt={it.name}
                        title={it.name}
                      />
                    </Partner>
                  )
                })}
              </Partners>
              {noButton ?
                null
                :
                (<ButtonC border to="/about"
                >Know More About Us</ButtonC>)
              }
                
            </Container>
          </Section>
        )
      }}
    />
  )
}
