import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql, StaticQuery } from 'gatsby';
import Section from '../layout/Section';
import Container from '../layout/Container';
import {SectionTitle, SectionHeader, Heading, P3, Img} from '../elements';
import getFormattedDate from '../../utils/getFormattedDate';
import {
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  hide_scrollbar,
  custom_scrollbar,
} from '../../styles/_mixins';

const Description = styled(P3).attrs({
  className: `md:w-2/3 lg:w-1/2`
})`
`;
const Blogs = styled.div.attrs({
  className: `flex pb-2 pt-3 rounded-sm mt-8`
})`
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  /* ${hide_scrollbar}; */
  ${custom_scrollbar};
`;
const Blog = styled.a.attrs(p => ({
  className: `rounded-lg h-60 mr-4`,
  href: p.link,
  target: '_blank',
  noopener: true,
}))`
  flex: 0 0 320px;
  overflow: hidden;
  scroll-snap-align: start;
  transition: box-shadow 0.2s ease-in;
  @media screen and (min-width: ${breakpoints.md}) {
    flex: 0 0 340px;
  }
  &:hover {
    box-shadow: 2px 2px 12px ${colors.bg_secondary_dark};
  }
`;
const ImgDiv = styled.div.attrs({
  className: `w-full h-48`
})`
`;
const Body = styled.div.attrs({
  className: `w-full p-4`
})`
  background-color: ${colors.bg_white};
`;
const Date = styled.span.attrs({
  className: `uppercase inline-block mb-2`
})`
`;
const Title = styled(P3).attrs({
  className: ``
})`
  margin-bottom: 0.2em;
  min-height: 3.1em;
`;
const BlogBody = styled(P3).attrs({
  className: ``,
  color: colors.text_gray,
  noMargin: true,
})`
  margin-bottom: 0.4em;
  max-height: 140px;
  overflow: hidden;
  display: inline-block;
`;

export default function HowItWorks({data}) {
  return (
    <StaticQuery
    query={graphql`
      query journalQuery {
        markdownRemark(frontmatter: {content: {eq: "journal"}}) {
          frontmatter {
            title
            description
            heading
            blog {
              date
              title
              link
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  `}
      render={(data, count) => {
        const content = data.markdownRemark.frontmatter;
        const blogs = data.markdownRemark.frontmatter.blog;
        return (
          <Section bg={colors.bg_secondary}>
            <Container>
              <SectionTitle>{content.title}</SectionTitle>
              <SectionHeader>{content.heading}</SectionHeader>
              <Description>{content.description}</Description>
              <Blogs>
                {blogs.map(it => {
                  console.log(it.date);
                  return (
                    <Blog link={it.link}>
                      <ImgDiv>
                        <Img cover position="50% 0%" fluid={it.image.childImageSharp.fluid} />
                      </ImgDiv>
                      <Body>
                        <Date>{getFormattedDate(it.date)}</Date>
                        <Title fw="bold">{it.title}</Title>
                        <BlogBody>{it.description.substring(0, 100)}...</BlogBody>
                      </Body>
                    </Blog>
                  )
                })}
              </Blogs>
            </Container>
          </Section>
        )
      }}
    />
  )
}
