import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs';
import {
  breakpoints,
} from '../../styles/_variables';
const ModalC = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* outline: none;
  &:active {
    outline: none;
  } */
`;

const Fade = React.forwardRef((props, ref) => {
  const {
    in: open, children, onEnter, onExited, ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const FadeC = styled(Fade)`
  border: '2px solid #000';
  width: 96%;
  height: 56vmin;
  outline: none;
  &:active {
    outline: none;
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    width: 90%;
    height: 50vmin;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 80%;
    height: 62vmin;
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    width: 70%;
    height: 72vmin;
  }
`;
Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

export default function VideoModal(props) {
  const [open, setOpen] = React.useState(false);
  const {
    isVideoModalOpen,
  } = props;
  useEffect(() => {
    setOpen(isVideoModalOpen);
  }, [isVideoModalOpen]);
  const handleClose = () => {
    props.setIfVideoModalOpen(false);
    setOpen(false);
  };
  return (
    <div>
      <ModalC
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        disableAutoFocus
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <FadeC in={open}>
          {props.children}
        </FadeC>
      </ModalC>
    </div>
  );
}

VideoModal.propTypes = {
  isVideoModalOpen: PropTypes.bool.isRequired,
};
