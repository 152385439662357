import React, {useRef} from 'react'
import Container from './layout/Container';
import Section from './layout/Section';
import {P, P2, SectionTitle} from './elements';
import styled from 'styled-components';
import {ChevronDown} from '@styled-icons/entypo/ChevronDown';
import {
  colors,
} from '../styles/_variables';
import serviceData from '../../content/service.yaml';

const CheckBox = styled.input.attrs({
  type: "checkbox",
  name: "faq",
  className: `faq-checkbox
    `,
})`
  display: none;
  &:checked {
    & ~ .question .chevron {
      transform: rotateZ(180deg);
    }
    & ~ .answer {
      height: auto;
      max-height: 40em;
    }
  }
`;
const Label = styled.label.attrs({
  className: `w-full h-auto`
})`
  border-bottom: 1px solid ${colors.primary};
`;
const QuestionC = styled.div.attrs({
  className: `question
    flex justify-between items-center cursor-pointer`,
})`
`;
const Question = styled(P2).attrs({
  className: `mr-4`,
  fw: 600,
  noMargin: true,
})`
`;
const Answer = styled(P2).attrs({
  className: `answer h-0 w-full mt-2`,
  onClick: e=>e.preventDefault(),
  noMargin: true,
})`
  color: ${colors.text_black_thin};
  max-height: 0;
  /* height: 0; */
  overflow: hidden;
  transition: max-height .5s ease-in-out;
`;
const Chevron = styled(ChevronDown).attrs({
  className: `chevron w-6
    `,
})`
  flex-shrink: 0;
  color: ${colors.primary};
  transform: rotateZ(0deg);
  transform-origin: center;
  transition: transform 0.35s ease;
`;
const FAQs = styled.div.attrs({
  className: `mt-16`,
})`
  border-top: 1px solid ${colors.primary_light};
`;
const FAQDiv = styled.div.attrs({
  className: `py-4 px-2`,
})`
  border-bottom: 1px solid ${colors.primary_light};
`;

type FAQ = {
  question: string;
  answer: string;
}

const FAQ: React.FC = () => {
  const FAQData = serviceData.faq;
  return (
    <Section>
      <Container>
        <SectionTitle>FREQUENTLY ASKED QUESTIONS</SectionTitle>
        <FAQs>
          {FAQData.map((it: FAQ) => (
            <FAQDiv>
              <Label>
                <CheckBox />
                <QuestionC>
                  <Question>{it.question}</Question>
                  <Chevron />
                </QuestionC>
                <Answer>{it.answer}</Answer>
              </Label>
            </FAQDiv>
          ))}
        </FAQs>
      </Container>
    </Section>
  )
}

export default FAQ;
