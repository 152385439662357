function getFormattedDate(dateString) {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const date = new Date(dateString).getDate();
  const month = new Date(dateString).getMonth();
  const year = new Date(dateString).getFullYear();

  return `${date} ${monthNames[month]} ${year}`
}

export default getFormattedDate;

