import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Section from '../layout/Section';
import Container from '../layout/Container';
import {Heart} from '@styled-icons/entypo/Heart';
import {SectionTitle, Heading, P3, Button} from '../elements';
const illustration1 = require('../../assets/svg/how-it-works/illustration1.svg');
const illustration2 = require('../../assets/svg/how-it-works/illustration2.svg');
const illustration3 = require('../../assets/svg/how-it-works/illustration3.svg');
const illustration4 = require('../../assets/svg/how-it-works/illustration4.svg');
const illustration1_2 = require('../../assets/svg/how-it-works/illustration1-2.svg');
const illustration2_2 = require('../../assets/svg/how-it-works/illustration2-2.svg');
const illustration3_2 = require('../../assets/svg/how-it-works/illustration3-2.svg');
const illustration4_2 = require('../../assets/svg/how-it-works/illustration4-2.svg');
import {
  colors,
  breakpoints,
} from '../../styles/_variables';

const Steps = styled.div.attrs({
  className: `flex flex-col mt-16 select-none`
})`
`;
const Step = styled.div.attrs({
  className: `w-full min-h-20 flex justify-between relative mb-20`
})`
  z-index: 0;
  &:nth-child(2n) {
    flex-direction: row-reverse;
    .illustration {
      background-position: center right;
    }
    .illustration-desktop img {
      object-position: left center;
    }
  }
  &:nth-child(1) {
    .illustration-desktop img {
      object-position: 30% 50%;
      height: 150%;
      @media screen and (max-width: ${breakpoints.lg}) {
        height: 180%;
      }
    }
    @media screen and (max-width: ${breakpoints.lg}) {
      .illustration-desktop {
        z-index: -1;
      }
    }
  }
  &:nth-child(2) {
    .illustration-desktop img {
      object-position: 60% 30%;
      height: 160%;
      @media screen and (max-width: ${breakpoints.lg}) {
        object-position: 65% 30%;
        height: 170%;
      }
    }
  }
  &:nth-child(3) {
    .illustration-desktop {
      @media screen and (max-width: ${breakpoints.lg}) {
        z-index: -1;
      }
    }
    .illustration-desktop img {
      object-position: 60% center;
      height: 140%;
      @media screen and (max-width: ${breakpoints.lg}) {
        object-position: 100% center;
        height: 130%;
      }
      @media screen and (max-width: ${breakpoints.xl}) {
        object-position: 100% center;
        height: 130%;
      }
    }
  }
  &:nth-child(4) {
    margin-bottom: 2em;
    .illustration-desktop img {
      object-position: 25% center;
      @media screen and (max-width: ${breakpoints.lg}) {
        object-position: 0% center;
        height: 90%;
        width: 90%;
      }
    }
  }
`;
const HeadingC = styled(Heading).attrs({
  className: `xs:w-10/12 md:w-9/12`
})`
`;
const StepCard = styled.div.attrs({
  className: `rounded-lg relative p-4 lg:p-8 pt-40 md:pt-20
    flex flex-col`
})`
  background-color: ${colors.bg_white};
  z-index: 0;
  @media screen and (min-width: ${breakpoints.md}) {
    width: 60%;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 40%;
    padding-top: 5em;
    min-height: 50vh;
  }
  &::before {
    content: "${p => p.letter}";
    font-weight: bold;
    font-size: 6rem;
    color: ${colors.bg_primary_light_accent};
    position: absolute;
    top: 2rem;
    left: ${p => p.right? "auto": "2rem"};
    right: ${p => p.right? "2rem": "auto"};
    width: 5rem;
    height: 5rem;
    @media screen and (max-width: ${breakpoints.md}) {
      top: 3rem;
    }
  }
`;
const Link = styled.a.attrs({
  className: `xs:w-10/12 md:w-5/12 self-end`
})`
  margin-left: auto;
  display:block;
  align-self: flex-end;
  border: 1px solid red;
`;
const CTA = styled(Button).attrs({
  className: `self-end`
})`
  @media screen and (min-width: ${breakpoints.md}) {
    width: 60%;
    margin-left: auto;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 40%;
  }
`;
const Illustration  = styled.div.attrs({
  className: `illustration-desktop hidden
    w-full h-full absolute md:block`
})`
  z-index: 1;
  right: 0;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center;
  }
`;
const IllustrationMobile  = styled.div.attrs({
  className: `illustration-mobile
    w-full h-full absolute md:hidden`
})`
  z-index: 1;
  top: calc(-90vw + ${p => p.top || '20em'});
  right: 0;
  padding-left: 10px;
  padding-right: 10px;
  & img {
    object-fit: contain;
    object-position: center bottom;
  }
  @media screen and (min-width: ${breakpoints.sm}) {
    top: calc(-70vw + ${p => p.top || '20em'});
  }
  @media screen and (min-width: 625px) {
    top: calc(-66vw + ${p => p.top || '20em'});
  }
`;


export default function HowItWorks({data}) {
  const images = useStaticQuery(graphql`
    query {
      illustration1: file(relativePath: { eq: "images/home/how-it-works/illustration1.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      illustration2: file(relativePath: { eq: "images/home/how-it-works/illustration2.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      illustration3: file(relativePath: { eq: "images/home/how-it-works/illustration3.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      illustration4: file(relativePath: { eq: "images/home/how-it-works/illustration4.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  );
  return (
    <Section>
      <Container>
        <SectionTitle>{data.title}</SectionTitle>
        <Steps>
          <Step>
            <StepCard letter="१">
              <HeadingC 
                color={colors.primary}
                fw="bold"
                lh="1.5"
              >{data.steps.step1.heading}</HeadingC>
              <P3 color={colors.text_gray}>{data.steps.step1.description}</P3>
            </StepCard>
            <Illustration>
              <img src={illustration1_2} atl="step 1" />
            </Illustration>
            <IllustrationMobile top="18em">
              <img src={illustration1} atl="step 1" />
            </IllustrationMobile>
          </Step>
          <Step>
            <StepCard letter="२" right>
              <HeadingC 
                color={colors.primary}
                fw="bold"
                lh="1.5"
              >{data.steps.step2.heading}</HeadingC>
              <P3 color={colors.text_gray}>{data.steps.step2.description}</P3>
            </StepCard>
            <Illustration>
              <img src={illustration2_2} atl="step 2" />
            </Illustration>
            <IllustrationMobile top="17em">
              <img src={illustration2} atl="step 2" />
            </IllustrationMobile>
          </Step>
          <Step>
            <StepCard letter="३">
              <HeadingC 
                color={colors.primary}
                fw="bold"
                lh="1.5"
              >{data.steps.step3.heading}</HeadingC>
              <P3 color={colors.text_gray}>{data.steps.step3.description}</P3>
            </StepCard>
            <Illustration top="19em">
              <img src={illustration3_2} atl="step 3" />
            </Illustration>
            <IllustrationMobile top="20em">
              <img src={illustration3} atl="step 3" />
            </IllustrationMobile>
          </Step>
          <Step>
            <StepCard letter="४" right>
              <HeadingC
                color={colors.primary}
                fw="bold"
                lh="1.5"
              >{data.steps.step4.heading}</HeadingC>
              <P3 color={colors.text_gray}>{data.steps.step4.description}</P3>
            </StepCard>
            <Illustration>
              <img src={illustration4_2} atl="step 4" />
            </Illustration>
            <IllustrationMobile top="18em">
              <img src={illustration4} atl="step 4" />
            </IllustrationMobile>
          </Step>
          <Step>
              <CTA href="/donate" full>{data.cta}&nbsp;&nbsp;<Heart size={'1.2em'} /></CTA>
          </Step>
        </Steps>
      </Container>
    </Section>
  )
}
