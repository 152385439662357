import React from 'react'
import Container from '../components/layout/Container';
import PageLayout from '../components/PageLayout';
import SEO from "../components/seo";
import Hero from '../components/home/Hero';
import Metrics from '../components/home/Metrics';
import HowItWorks from '../components/home/HowItWorks';
import Journal from '../components/home/Journal';
import Partners from '../components/Partners';
import FAQ from '../components/FAQ';
import { graphql, useStaticQuery } from 'gatsby';
import Section from '../components/layout/Section';
export default function indexPage() {
  const query = useStaticQuery(graphql`
    query MyQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          hero {
            cta
            description
            tagline
            heading
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          metrics {
            heading
            amount
            amountFrom
            progress
            metrics {
              metric1 {
                number
                name
              }
              metric2 {
                number
                name
              }
              metric3 {
                number
                name
              }
            }
          }
          howItWorks {
            title
            steps {
              step1 {
                heading
                description
              }
              step2 {
                heading
                description
              }
              step3 {
                heading
                description
              }
              step4 {
                heading
                description
              }
            }
          }
        }
      }
    }
  `);
  return (
    <PageLayout>
      <SEO title="Home" />
      <Hero data={query.markdownRemark.frontmatter.hero}/>
      <Metrics data={query.markdownRemark.frontmatter.metrics}/>
      <HowItWorks data={{
        ...query.markdownRemark.frontmatter.howItWorks, 
        cta: query.markdownRemark.frontmatter.hero.cta
      }}/>
      <Journal />
      <Partners />
      <FAQ />
    </PageLayout>
  )
}
