import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  colors,
} from '../../styles/_variables.js';

const Section = styled.section.attrs((p) => ({
    className: `py-8 md:py-10 relative ${p.screen && 'lg:h-screen'}`,
}))`
  background-color: ${(p) => p.bg || colors.bg_primary};
`;

export default Section;

Section.propTypes = {
  bg: PropTypes.string,
  screen: PropTypes.bool,
}